<script>
  import { Actions } from '@/shared/store/actionsTypes';
  import { mapGetters } from 'vuex';

  export default {
    name: 'MembershipTypes',
    data: () => ({
      checkboxActive: false,
    }),
    computed: {
      ...mapGetters([
        'shopifyCollection',
        'membershipInCart',
      ]),
      selectedMembership: {
        get() {
          return this.membershipInCart;
        },
        set(value) {
          this.$store.dispatch('setMembershipType', value);
        },
      },
      membershipTypes() {
        // need to filter out all in one bundle subscription so check if monthlyPrice is present
        return this.shopifyCollection.filter(item => item.productType === 'subscription' && item.metadata.monthlyPrice);
      },
    },
    methods: {
      toggleActive() {
        this.checkboxActive = !this.checkboxActive;
      },
      openMembershipModal() {
        this.$store.dispatch(Actions.OPEN_MODAL, { component: 'membership' });
      },
    },
  };
</script>

<template>
  <div class="membership-types">
    <h3>Select FightCamp Membership</h3>
    <p class="membership-types-description">
      Choose when you're billed.
    </p>
    <p class="membership-types-description">
      Membership begins upon equipment delivery. <sup class="membership-types-modal" @click="openMembershipModal">ⓘ</sup>
    </p><br>
    <div class="membership-types-memberships">
      <div
        v-for="membership in membershipTypes"
        :key="membership.variants[0].id"
        class="membership-types-membership">
        <label
          :for="membership.variants[0].id"
          class="membership-types-membership-label"
          :class="{'membership-types-membership-label-active': selectedMembership.variants[0].id === membership.variants[0].id }"
          v-track-click="membership.variants[0].id">
          <div class="membership-types-membership-details">
            <div class="membership-types-membership-checkbox" :class="{'membership-types-membership-checkbox-active': selectedMembership.variants[0].id=== membership.variants[0].id }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffffff"
                width="20"
                height="20"
                viewBox="0 0 24 24">
                <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
              </svg>
            </div>
            <input
              :id="membership.variants[0].id"
              v-model="selectedMembership"
              type="radio"
              class="membership-types-membership-input"
              :name="membership.name"
              :value="membership"
              :checked="selectedMembership.variants[0].id === membership.variants[0].id">
            <div class="membership-types-membership-title">
              <div class="membership-types-membership-name">{{ membership.title }}</div>
            </div>
            <div class="membership-types-membership-pricing">
              <div>
                <p class="membership-types-membership-monthly-price">
                  {{ parseInt(membership.metadata.monthlyPrice, 10) | currency }}
                </p>
                <span class="membership-types-membership-financing-month">/month</span>
              </div>
              <p class="membership-types-membership-detail">{{ membership.description }}</p>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.membership-types {
  padding: 8px;

  @media #{$tablet-up} {
    padding: 16px;
  }

  &-description {
    margin: 0;
  }

  &-modal {
    font-size: 13px;
    cursor: pointer;
    vertical-align: bottom;
    font-weight: bold;
  }

  &-memberships {
    margin-bottom: 2rem;
  }

  &-membership-label {
    display: flex;
    border: 2px solid $color-dark-gray;
    border-radius: $input-radius;
    padding: 10px 20px;
    min-height: 75px;
    margin: 5px 0;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: space-between;

    @media #{$tablet} {
      padding: 10px 20px;
      margin: 10px 0;
    }

    @media #{$desktop} {
      padding: 10px 20px;
      margin: 10px 0;
    }
  }

  &-membership-column {
    display: flex;
    align-items: center;
    width: 50%;
  }

  &-membership-label-active {
    border: 2px solid #414141;
  }

  &-membership-checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border: 2px solid $color-dark-gray;
    border-radius: 5px;
  }

  &-membership-checkbox-active {
    border: 2px solid #414141;
    background-color: #414141;
  }

  &-membership-input {
    // hide radio button visually
    position: absolute;
    top: auto;
    left: -20000px;
  }

  &-membership-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$tablet-up} {
      align-items: flex-start;
      margin-left: 1rem;
    }
  }

  &-membership-name {
    cursor: pointer;
    font-size: 18px;
    font-family: $font-stack-regular;
  }

  &-membership-price-info {
    font-size: 0.875rem;
  }

  &-membership-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @media #{$tablet-up} {
      display: grid;
      justify-items: left;
      grid-template-columns: 1fr 150px 340px;
    }
  }

  &-membership-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    @media #{$tablet-up} {
      align-items: flex-start;
      margin: 0 0 0 5rem;
    }
  }

  &-membership-monthly-price {
    display: inline;
    font-family: $font-stack-bold;
    font-size: 1.5rem;
    letter-spacing: 1px;
    margin: 0;
    position: relative;
    text-align: center;

    &::before {
      content: "$";
      flex-grow: 0;
      font-family: $font-stack-regular;
      font-size: 1rem;
      opacity: 0.6;
      position: absolute;
      left: -0.75rem;
      top: -0.5rem;
    }
  }

  &-membership-financing-month {
    color: $color-blue;
    font-size: 1rem;
    opacity: 0.6;
    margin-left: 0;
  }

  &-membership-detail {
    margin: 0;
    font-size: 0.75rem;
  }
}
</style>
