<template>
  <div class="fc-container-lg fc-flex fc-column fc-container-lg fc-bg-white biz-dev">
    <h1>Biz Dev POS</h1>
    <div class="notes">
      <h2>Notes:</h2>
      <ul>
        <li class="fc-text-red">
          For <strong>InsideSales</strong> or <strong>BizDev</strong> please toggle on correct sale environment.
          <div class="fc-flex fc-align-center">
            <p class="fc-bold" :class="!isPosChecked ? 'fc-text-red' : 'fc-text-light-gray-1'">
              BizDev
            </p>
            <input
              id="pos-on"
              v-model="isPosChecked"
              class="toggle toggle-theme-red"
              type="checkbox">
            <label class="toggle-btn fc-margin-x-3" for="pos-on" />
            <p class="fc-bold" :class="isPosChecked ? 'fc-text-red' : 'fc-text-light-gray-1'">
              InsideSales
            </p>
          </div>
        </li>
      </ul>
    </div>
    <Checkout />
  </div>
</template>

<script>
  import { mapMutations, mapGetters } from 'vuex';
  import Checkout from './components/Checkout.vue';

  export default {
    name: 'BizDev',
    components: {
      Checkout,
    },
    data() {
      return {
        isPosChecked: false,
      };
    },
    computed: {
      ...mapGetters([
        'isPosOn',
      ]),
      collectionId() {
        return this.isPosChecked ? process.env.VUE_APP_SHOPIFY_POS_COLLECTION : process.env.VUE_APP_SHOPIFY_BIZDEV_COLLECTION;
      },
    },
    methods: {
      ...mapMutations([
        'setRepresentative',
        'setLocation',
      ]),
      transformSizeVariants(collection) {
        const items = [];

        collection.forEach((item) => {
          const variants = item.variants;
          const title = item.title;

          variants.forEach((variant) => {
            items.push({
              id: variant.id,
              title: `${title}${ variant.title !== 'Default Title' ? ` - ${variant.title}` : ''}`,
              price: variant.price,
              oldPrice: variant.compareAtPrice,
              productType: item.productType,
            });
          });
        });

        return items;
      },
      async getCollection() {
        const resp = await this.$eCommService.getCollection({ id: this.collectionId });
        const filteredOutSubs = resp.filter(item => item.productType !== 'subscription');
        const transformedResp = this.transformSizeVariants(filteredOutSubs);
        const groupedItems = Object.values(transformedResp.reduce((acc, item) => {
          const key = item.productType;
          const newGrouped = { ...acc };
          if (!newGrouped[key]) {
            newGrouped[key] = [];
          }
          newGrouped[key].push(item);
          return newGrouped;
        }, {}));
        this.$store.commit('setCollections', { collection: resp, transformedCollection: groupedItems });
      },
    },
    created() {
      const location = this.isPosChecked ? 'POS' : 'BizDev';
      this.setLocation(location);
      this.setRepresentative(this.$auth.user.name);
    },
    async mounted() {
      await this.getCollection();
    },
    watch: {
      async isPosChecked(newVal) {
        this.$store.commit('setPos', newVal);
        await this.getCollection();

        const location = this.isPosChecked ? 'POS' : 'BizDev';
        this.setLocation(location);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .notes {
    margin: 1rem;
    text-align: left;
  }

  // TODO refactor toggle component and move to Angie UI
  .toggle {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .toggle-btn {
      box-sizing: border-box;
      &::selection {
        background: none;
      }
    }

    + .toggle-btn {
      outline: 0;
      display: block;
      width: 4em;
      height: 2em;
      position: relative;
      cursor: pointer;
      user-select: none;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked + .toggle-btn:after {
      left: 50%;
    }
  }

  // theme
  .toggle-theme-red {
    + .toggle-btn {
      background: #f0f0f0;
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;
      &:after {
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
      }
    }

    &:checked + .toggle-btn {
      background: $color-red;
    }
  }
</style>
