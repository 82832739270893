<template>
  <div>
    <div class="fc-container-lg">
      <div class="fc-flex fc-row">
        <div
          v-for="items in mainItems"
          :key="items[0].id"
          class="items-column">
          <h2>
            {{ items[0].productType }}
          </h2>
          <ShopifyItem
            v-for="item in items"
            :key="item.id"
            :product="item" />
        </div>
      </div>
      <div>
        <h2>
          {{ accessoryTitle }}
        </h2>
        <div class="accessories-container fc-container-lg">
          <ShopifyItem
            v-for="item in accessories"
            :key="item.id"
            class="item"
            :product="item" />
        </div>
      </div>
    </div>
    <ShopifyCheckoutForm />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ShopifyCheckoutForm from './ShopifyCheckoutForm.vue';
  import ShopifyItem from './ShopifyItem.vue';

  export default {
    name: 'Checkout',
    components: {
      ShopifyItem,
      ShopifyCheckoutForm,
    },
    computed: {
      ...mapGetters([
        'transformedCollection',
      ]),
      mainItems() {
        return this.transformedCollection.filter(item => item[0].productType === 'package' || item[0].productType === 'packageUpsell');
      },
      accessories() {
        return this.transformedCollection.filter(item => item[0].productType === 'accessory')[0];
      },
      accessoryTitle() {
        const acc = this.transformedCollection.filter(item => item[0].productType === 'accessory');
        if (acc.length > 0) {
          return acc[0][0].productType;
        }
        return '';
      },
    },
  };
</script>

<style lang="scss">
  .items-column {
    flex: 0 1 50%;

    h2 {
      text-align: center;
    }
  }

.accessories-container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 48%;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
}
</style>
