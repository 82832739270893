<script>
  import { mapGetters } from 'vuex';
  import CartItemShopify from './CartItemShopify.vue';
  import CartSubItemShopify from './CartSubItemShopify.vue';

  export default {
    name: 'CartDisplayShopify',
    components: {
      CartItemShopify,
      CartSubItemShopify,
    },
    props: {
      isDrawer: {
        default: false,
        type: Boolean,
      },
    },
    data: () => ({
      inputcoupon: '',
      isPromoMessageActive: true,
    }),
    computed: {
      ...mapGetters([
        'cart',
        'membershipInCart',
        'cartCoupon',
        'cartCouponError',
        'summary_coupon',
        'contactEmail',
        'processingTotalCost',
        'shipping',
      ]),
      isCartEmpty() {
        return this.cart.length === 0;
      },
      showPromoMessage() {
        return !!this.isPromoMessageActive && !!(this.contactEmail && this.contactEmail !== '') && !!(!this.cartCoupon || this.cartCoupon === '');
      },
      isUSOrder() {
        return this.$store?.state?.checkout?.shipping?.country === 'US';
      },
      summaryLines() {
        return {
          subtotal: this.$store.getters.summary_subtotal,
          shipping: this.$store.getters.summary_shipping,
          tax: this.$store.getters.summary_tax,
          total: this.$store.getters.summary_total,
          duties: this.$store.getters.summaryDuties,
        };
      },
      productsInCartDetails() {
        return this.cart.map((cartItem) => {
          const product = this.$store.getters.getProductVariantById(cartItem.id) ?? {};
          const productVariant = product?.variants?.find(v => v.id === cartItem.id) ?? {};
          const productName = product?.variants?.length > 1 ? `${product?.title}, ${productVariant?.name}` : product?.title;
          return {
            ...cartItem,
            productName,
            pricingDetails: product?.description,
            pricingDescription: product?.description,
            price: productVariant?.price,
            productType: product?.productType,
          };
        }).filter((item) => item.productType !== 'subscription');
      },
      couponLine() {
        if (this.summary_coupon === 0 || !this.summary_coupon) {
          return '--';
        }

        // handle subscription credit coupons
        if (this.summary_coupon === 1) {
          return 'APPLIED';
        }

        const displayedAmount = -1 * this.summary_coupon;
        return this.$options.filters.currency(displayedAmount);
      },
    },
    methods: {
      removeItem(item) {
        this.$store.dispatch('removeItemFromCartV2', item);
      },
      clickApplyCoupon() {
        this.$store.dispatch('applyCoupon', { coupon: this.inputcoupon, eCommService: this.$eCommService });
      },
      clearCoupon() {
        this.$analytics.couponRemoved(this.inputcoupon);
        this.$store.dispatch('removeCoupon', { eCommService: this.$eCommService });
        this.inputcoupon = '';
      },
      toggleShowPromoMessage() {
        this.isPromoMessageActive = !this.isPromoMessageActive;
      },
    },
    watch: {
      inputcoupon() {
        if (this.inputcoupon) {
          this.inputcoupon = this.inputcoupon.toUpperCase().replace(/\s/g, '');
        }
      },
      cart: {
        async handler() {
          if (this.$store?.state?.checkout?.cartId) {
            await this.$store.dispatch('updateCheckout', { shippingDetails: this.shipping, eCommService: this.$eCommService });
          }
        },
        deep: true,
      },
    },
  };
</script>

<template>
  <div class="cart-frame padding-x-8">
    <div class="cart-frame__item-container">
      <h2 v-show="isCartEmpty" class="fc-text-blue fc-heading-1 fc-uppercase fc-text-center fc-padding-y-5">
        Cart Empty
      </h2>
      <div v-show="!isCartEmpty" class="cart-frame__item-container-scroll">
        <div
          v-for="item in productsInCartDetails"
          :key="item.productName"
          class="fc-margin-y-4">
          <CartItemShopify
            :item="item"
            :enable-remove="true"
            closer-drawer="false"
            @removeFromCart="removeItem" />
        </div>
        <div class="fc-margin-y-4">
          <CartSubItemShopify
            v-if="membershipInCart"
            :is-drawer="true"
            @removeFromCart="removeItem" />
        </div>
      </div>
    </div>
    <div class="cart-frame__pricing-container fc-text-blue fc-padding-y-6" :class="{'cart-frame__processing': processingTotalCost, 'cart-frame__pricing-container--drawer': isDrawer}">
      <div class="fc-flex fc-justify-between fc-align-center">
        <p class="fc-bold fc-font-size-5 fc-margin-0">
          Subtotal
        </p>
        <p class="cart-frame__price fc-font-size-5 fc-text fc-text-right fc-padding-left-6">
          {{ summaryLines.subtotal | currency }}
        </p>
      </div>
      <div v-if="!showPromoMessage && !!(contactEmail && contactEmail !== '')" class="cart-frame__row fc-flex fc-justify-between fc-align-center">
        <div class="cart-frame__promo-block fc-flex fc-align-center">
          <p class="fc-bold fc-font-size-5 fc-margin-0">
            Code
          </p>
          <input
            v-model="inputcoupon"
            :disabled="!!cartCoupon"
            class="cart-frame__input-discount fc-font-size-4"
            :class="{'cart-frame__input-discount-error': cartCouponError}">
          <button
            v-if="!(cartCoupon || processingTotalCost) "
            type="button"
            aria-label="apply coupon"
            class="cart-frame__coupon-button fc-font-size-5 fc-text-red fc-underline"
            v-track-click="`${$options.name}_ApplyPromo`"
            @click="clickApplyCoupon">
            Apply
          </button>
          <font-awesome-icon
            v-if="cartCoupon"
            :icon="['fas', 'times']"
            @click="clearCoupon" />
        </div>
        <div class="cart-frame__item-promo-price fc-text-right">
          <div> {{ couponLine }} </div>
        </div>
      </div>
      <p v-if="cartCouponError" class="fc-margin-0 fc-font-size-5 fc-text-left fc-text-red fc-margin-top-1">
        {{ cartCouponError }}
      </p>
      <div v-if="!isDrawer" class="cart-frame__row fc-flex fc-justify-between fc-align-center fc-padding-y-1">
        <p class="fc-bold fc-font-size-5 fc-margin-0">
          Shipping
        </p>
        <div class="cart-frame__tax-shipping-price fc-padding-left-6 fc-text-right">
          <p v-if="summaryLines.shipping != null" class="fc-font-size-5 fc-margin-0">
            {{ summaryLines.shipping | currency }}
          </p>
          <p v-else class="fc-font-size-5 fc-margin-0">
            --
          </p>
        </div>
      </div>
      <div v-if="!isDrawer" class="cart-frame__row fc-flex fc-justify-between fc-align-center">
        <p class="fc-bold fc-font-size-5 fc-margin-0">
          Tax
        </p>
        <div class="cart-frame__tax-shipping-price fc-padding-left-6 fc-text-right">
          <p v-if="summaryLines.tax != null" class="fc-font-size-5 fc-margin-0">
            {{ summaryLines.tax | currency }}
          </p>
          <p v-else class="fc-font-size-5 fc-margin-0">
            --
          </p>
        </div>
      </div>
      <div v-if="showPromoMessage" class="fc-padding-y-1 fc-text-left">
        <a
          class="fc-bold fc-font-size-5 fc-text-red fc-underline"
          v-track-click="`${$options.name}_EnterPromo`"
          @click="toggleShowPromoMessage">Enter Promo Code</a>
      </div>
      <div v-if="processingTotalCost" class="cart-frame__spinner-container fc-flex fc-justify-center fc-align-center">
        <font-awesome-icon
          :icon="['fas', 'circle-notch']"
          spin
          class="fc-text-red fc-font-size-8" />
      </div>
      <div v-if="!isDrawer" class="fc-flex fc-justify-between fc-align-center">
        <p class="fc-bold fc-font-size-5 fc-margin-0">
          Order Total:
        </p>
        <p class="cart-frame__price fc-font-size-5 fc-text fc-text-right fc-padding-left-6">
          {{ summaryLines.total | currency }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '@fc/angie-ui/dist/styles/exports';

  .cart-frame {
    box-sizing: border-box;
    padding: 10px 0;
    color: $color-blue;
    width: get-block-size-level(full);
    height: get-block-size-level(full);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__item-container {
      box-sizing: border-box;
      width: get-block-size-level(full);
      overflow: auto;
    }

    &__item-container-scroll {
      width: get-block-size-level(full);
      overflow: auto;
      max-height: 340px;

      @media #{$tablet-up} {
        max-height: 450px;
      }
    }

    &__pricing-container {
      position: relative;
      box-sizing: border-box;
      width: get-block-size-level(full);
      border-top: 1px solid $color-blue;
      border-bottom: 1px solid $color-blue;

      &--drawer {
        border-bottom: none;
      }
    }

    &__processing {
      color: $color-gray-2;
    }

    &__spinner-container {
      position: absolute;
      width: get-block-size-level(full);
      height: get-block-size-level(full);
      top: 0;
    }

    &__input-discount {
      max-width: 110px;
      border-radius: 100px;
      padding: 0 10px;
      -webkit-appearance: none;
      border: $border-light-outline;
      margin-left: 5px;
      margin-right: 5px;
      font-family: "Courier New", Courier, monospace;

      @media #{$iphone-5} {
        max-width: 90px;
      }
    }

    &__input-discount:disabled {
      background-color: $color-light-gray;
    }

    &__input-discount-error {
      border-color: $color-accent;
      color: $color-accent;
    }

    &__item-promo-price {
      font-family: $font-stack-regular;
      font-size: 0.875rem;
      padding-left: 10px;

      @media #{$iphone-5} {
        font-size: get-font-size-level(4);
      }
    }

    &__price {
      min-width: 80px;

      @media #{$iphone-5} {
        font-size: get-font-size-level(4);
      }
    }

    &__coupon-button {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
</style>
