<template>
  <div class="fc-flex-row-between fc-bg-eggshell fc-padding-2 fc-margin-3 row">
    <p class="fc-margin-0 title">
      {{ product.title }}
    </p>
    <p class="price">
      <s v-if="product.oldPrice">
        {{ product.oldPrice | currency }}</s>
      {{ product.price | currency }}
    </p>
    <ButtonAction
      primary
      size="small"
      class="fc-margin-1"
      @click.native="addItemToCart()">
      {{ cta }}
    </ButtonAction>
  </div>
</template>

<script>
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ShopifyItem',
    components: {
      ButtonAction,
    },
    props: {
      product: { type: Object, default: () => {} },
    },
    computed: {
      ...mapGetters([
        'cartProducts',
      ]),
      cta() {
        return this.cartProducts?.packageInCart && this.product.productType === 'package' ? 'Replace in Cart' : 'Add to Cart';
      },
    },
    methods: {
      async addItemToCart() {
        this.$store.dispatch('insertItemToCartV2', {
          id: this.product.id,
          type: 'equip',
          disableDrawer: true,
          delay: true,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@fc/angie-ui/dist/styles/exports';

  .row {
    border-radius: $container-radius;
  }

  .title,
  .price {
    font-size: get-font-size-level(6);
  }

  .title {
    flex: 0 1 200px;
  }

  .price {
    flex: 0 1 70px;
  }
</style>
